// get item 

export const getAuth = JSON.parse(localStorage.getItem("auth"));
export const getProfile = JSON.parse(localStorage.getItem("profile"));
export const getListPlan = JSON.parse(sessionStorage.getItem("listPlan"));
export const getListStore = JSON.parse(sessionStorage.getItem("listStore"));

// set item

export const setAuth = (auth) => localStorage.setItem("auth", JSON.stringify(auth));
export const setProfile = (profile) => localStorage.setItem("profile", JSON.stringify(profile));
export const setListPlan = (list) => sessionStorage.setItem("listPlan", JSON.stringify(list));
export const setListStore = (list) => sessionStorage.setItem("listStore", JSON.stringify(list));