<template>
  <div class="sidebar collapsed-sidebar" id="collapsed-sidebar">
    <div class="sidebar-inner slimscroll">
      <div id="sidebar-menu-2" class="sidebar-menu sidebar-menu-three">
        <aside id="aside" class="ui-aside">
          <ul class="tab nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="tablinks nav-link active"
                href="#home"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                role="tab"
                aria-selected="true"
              >
                <img src="@/assets/img/icons/menu-icon.svg" alt="" />
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="tablinks nav-link"
                href="#messages"
                id="messages-tab"
                data-bs-toggle="tab"
                data-bs-target="#product"
                role="tab"
                aria-selected="false"
              >
                <img src="@/assets/img/icons/product.svg" alt="" />
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="tablinks nav-link"
                href="#set4"
                id="set-tab4"
                data-bs-toggle="tab"
                data-bs-target="#document"
                role="tab"
                aria-selected="true"
              >
                <vue-feather type="file-text"></vue-feather>
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="tablinks nav-link"
                href="#profile"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#sales"
                role="tab"
                aria-selected="false"
              >
                <img src="@/assets/img/icons/sales1.svg" alt="" />
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="tablinks nav-link"
                href="#set3"
                id="set-tab3"
                data-bs-toggle="tab"
                data-bs-target="#report"
                role="tab"
                aria-selected="true"
              >
                <img src="@/assets/img/icons/printer.svg" alt="" />
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="tablinks nav-link"
                href="#set5"
                id="set-tab6"
                data-bs-toggle="tab"
                data-bs-target="#permission"
                role="tab"
                aria-selected="true"
              >
                <vue-feather type="settings"></vue-feather>
              </a>
            </li>
          </ul>
        </aside>
        <div class="tab-content tab-content-four pt-2">
          <ul class="tab-pane active" id="home" aria-labelledby="home-tab">
            <li>
              <router-link
                :class="currentPath == '/dashboard' ? 'active' : 'notactive'"
                to="/dashboard"
                >Dashboard</router-link
              >
            </li>
          </ul>
          <ul class="tab-pane" id="product" aria-labelledby="messages-tab">
            <li>
              <router-link
                :class="
                  currentPath == '/inventory/product-list'
                    ? 'active'
                    : 'notactive'
                "
                to="/inventory/product-list"
                ><span>Products</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/inventory/add-product'
                    ? 'active'
                    : 'notactive'
                "
                to="/inventory/add-product"
                ><span>Create Product</span></router-link
              >
            </li>
          </ul>
          <ul class="tab-pane" id="document" aria-labelledby="set-tab4">
            <li>
              <router-link
                :class="
                  currentPath == '/stock/manage-stocks' ? 'active' : 'notactive'
                "
                to="/stock/manage-stocks"
                ><span>Manage Stock</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/stock/stock-adjustment' ? 'active' : 'notactive'
                "
                to="/stock/stock-adjustment"
                ><span>Stock Adjustment</span></router-link
              >
            </li>
          </ul>
          <ul class="tab-pane" id="sales" aria-labelledby="profile-tab">
            <li>
              <router-link
                :class="
                  currentPath == '/sales/sales-list' ? 'active' : 'notactive'
                "
                to="/sales/sales-list"
                ><span>Sales</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/sales/sales-returns' ? 'active' : 'notactive'
                "
                to="/sales/sales-returns"
                ><span>Sales Return</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="currentPath == '/sales/pos' ? 'active' : 'notactive'"
                to="/sales/pos"
                ><span>POS</span></router-link
              >
            </li>
          </ul>
          <ul class="tab-pane" id="report" aria-labelledby="set-tab3">
            <li>
              <router-link
                :class="
                  currentPath == '/reports/sales-report'
                    ? 'active'
                    : 'notactive'
                "
                to="/reports/sales-report"
                ><span>Sales Report</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/reports/purchase-report'
                    ? 'active'
                    : 'notactive'
                "
                to="/reports/purchase-report"
                ><span>Purchase report</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    currentPath() {
      return this.$route.fullPath;
    },
  },
};
</script>
