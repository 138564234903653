<template>
  <a-modal
    v-model:open="isOpen"
    :footer="null"
    :maskClosable="false"
    closeIcon
    @cancel="handleClose"
  >
    <Form
      @submit="submitForm"
      :validation-schema="schema"
      v-slot="{ errors }"
      class="text-center my-10"
    >
      <h3 class="my-5">Tambah Kategori</h3>
      <div class="text-start">
        <label class="form-label">Nama Kategori</label>
        <Field
          name="category"
          type="text"
          placeholder="Contoh : Kopi / Makanan / Laptop / Handphone..."
          class="form-control"
          :class="{ 'is-invalid': errors.category }"
        />
        <small v-if="errors.category" class="text-danger">
          {{ errors.category }}
        </small>
      </div>
      <div class="text-start my-5">
        <div class="form-label">Gambar</div>
        <label
          for="upload-image-category"
          class="my-2 p-5 border-4 border-dashed rounded-4 w-100 border-light-subtle"
        >
          <div class="justify-content-center d-flex">
            <img
              :style="imgCategory === null ? 'width: 10%' : 'width: 50%'"
              :src="imgCategory || require('@/assets/img/icons/ImageAdd.svg')"
              alt="Upload"
            />
          </div>
        </label>
        <Field type="file" name="imageCategory" v-slot="{ field }">
          <input
            v-bind="field"
            id="upload-image-category"
            ref="fileInputCategory"
            type="file"
            class="d-none"
            accept=".jpeg,.jpg,.png"
            @change="onFileChangeCategory($event, field)"
          />
        </Field>
        <small class="text-danger">
          {{ errors.imageCategory }}
        </small>
      </div>
      <div class="w-full">
        <button type="submit" class="btn btn-primary btn-block">
          Buat Kategori
        </button>
      </div>
    </Form>
  </a-modal>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { uploadMediaWithToken } from "@/services/global-url";
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";

export default {
  name: "ModalAddCategory",
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOpen: this.open,
      imgCategory: null,
      schema: Yup.object().shape({
        imageCategory: Yup.mixed()
          .required("Upload foto kategori kamu.")
          .test(
            "fileSize",
            "Ukuran gambar maksimal 2 MB.",
            (value) => value && value.size <= 2 * 1024 * 1024
          )
          .test(
            "fileType",
            "Format gambar harus JPEG, JPG, atau PNG.",
            (value) =>
              value &&
              ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
          ),
        category: Yup.string().required("Nama kategori tidak boleh kosong"),
      }),
    };
  },
  watch: {
    open: {
      immediate: true,
      handler(newVal) {
        this.isOpen = newVal;
      },
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:open", { showModal: false, addCategory: false });
    },
    async onFileChangeCategory(event, field) {
      const file = event.target.files[0];
      try {
        await this.schema.fields.imageCategory.validate(file);
        field.onChange(file);
        this.imgCategory = await uploadMediaWithToken(file);
      } catch (error) {
        this.$refs.fileInputCategory.value = "";
      }
    },
    async submitForm(values, { resetForm }) {
      const url = "/api/v1/category";
      const body = {
        name: values.category,
        image: this.imgCategory,
      };

      try {
        const response = await axiosWithAuth.post(url, body);
        if (response.data.meta.code === 200) {
          toast("success", "Kategori berhasil ditambahkan.");
          resetForm();
          this.imgCategory = "";
          this.$emit("update:open", { showModal: false, addCategory: true });
        }
      } catch (error) {
        toast("error", "Gagal menambahkan kategori.");
      }
    },
  },
};
</script>
