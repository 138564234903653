<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Menunggu Pembayaran</h4>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="px-5">
          <div class="card-body justify-content-center d-flex">
            <div class="border border-2 rounded-4" style="width: 500px">
              <a-skeleton v-if="isLoading" active />
              <div
                v-else
                class="p-4 rounded-top-4"
                style="background-color: #f0eef6"
              >
                <div class="text-center fs-30 fw-bold">
                  {{ formatIDR(subscription.amount, false) }}
                </div>
                <div class="mt-5">
                  <strong class="fs-20">Detail pembayaran</strong>
                  <div class="fs-17">
                    <div class="d-flex justify-content-between mt-4">
                      <div class="">Nama Paket</div>
                      <div class="d-flex justify-content-end">
                        <img
                          v-if="subscription.plan_name === 'Lite'"
                          src="@/assets/img/icon/icon-lite-plan.svg"
                          style="width: 25px"
                          alt="Lite"
                        />
                        <img
                          v-if="subscription.plan_name === 'Plus'"
                          src="@/assets/img/icon/icon-plus-plan.svg"
                          style="width: 25px"
                          alt="Plus"
                        />
                        <img
                          v-if="subscription.plan_name === 'Premium'"
                          src="@/assets/img/icon/icon-premium-plan.svg"
                          style="width: 25px"
                          alt="Premium"
                        />
                        <div class="ms-1 fw-bold">
                          {{ subscription.plan_name }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between my-4">
                      <div class="align-self-center">Status</div>
                      <div
                        class="p-1 rounded-pill fw-normal"
                        :class="
                          isLoadingStatus
                            ? ''
                            : getClassStatus(
                                subscription.pending_payment.status
                              )
                        "
                      >
                        <a-spin v-if="isLoadingStatus" />
                        <span v-else>
                          {{ getStatus(subscription.pending_payment.status) }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="">Tanggal Pembayaran</div>
                      <div class="fw-bold">
                        {{ DDMMYYYY(subscription.expired_at) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-5">
                  <a-divider />
                </div>
                <div class="text-center">
                  Selesaikan pembayaran anda untuk melanjutkan penggunaan akun
                  Kasirkita
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isLoading" class="d-flex justify-content-center gap-5 my-10">
          <button
            class="btn btn-outline-primary rounded rounded-pill"
            @click="(isLoadingStatus = true), getSubscription()"
          >
            Saya Sudah Bayar
          </button>
          <a :href="subscription.pending_payment.link" target="_blank">
            <button class="btn btn-primary rounded rounded-pill">
              Bayar Sekarang
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatIDR } from "@/utils/libs";
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";
import { DDMMYYYY } from "@/utils/formatDate";

export default {
  data() {
    return {
      formatIDR,
      isLoading: true,
      isLoadingStatus: false,
      subscription: null,
      DDMMYYYY,
    };
  },
  mounted() {
    this.getSubscription();
  },
  methods: {
    getSubscription() {
      const url = "/api/v1/subscription";
      axiosWithAuth
        .get(url)
        .then((response) => {
          this.isLoading = false;
          this.isLoadingStatus = false;
          if (response.data.meta.code === 200) {
            this.subscription = response.data.data;
          }
        })
        .catch(() => {
          toast("error");
          this.isLoading = false;
          this.isLoadingStatus = false;
        });
    },
    getStatus(value) {
      let result;
      if (value === "pending") {
        result = "Menunggu Pembayaran";
      } else if (value === "settlement") {
        result = "Berhasil Dibayar";
      } else {
        result = "Gagal";
      }
      return result;
    },
    getClassStatus(value) {
      let result;
      if (value === "pending") {
        result = "pending-status";
      } else if (value === "settlement") {
        result = "success-status";
      } else {
        result = "failed-status";
      }
      return result;
    },
  },
};
</script>
<style scoped>
.pending-status {
  background-color: #fffae6;
  color: #ffcc00;
}
.success-status {
  background-color: #ebf9ee;
  color: #34c759;
}
.failed-status {
  background-color: #dfcaca;
  color: #cc1111;
}
</style>
