<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="justify-content-between d-flex w-100">
          <div class="page-title align-self-center">
            <h4>Tambah Produk</h4>
          </div>
          <ul class="table-top-head">
            <li>
              <div class="page-btn">
                <router-link to="/onboarding" class="btn btn-secondary">
                  <vue-feather type="arrow-left" class="me-2"></vue-feather
                  >Kembali ke Onboarding
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="page-wrapper-new p-0">
            <div class="content">
              <div class="justify-content-between mb-5">
                <div class="page-title fw-bold">
                  <h4>Tambah Produk</h4>
                </div>
              </div>
              <div class="new-employee-field">
                <Form
                  @submit="validateSubmitProduct"
                  :validation-schema="schema"
                  v-slot="{ errors }"
                >
                  <div class="mb-5">
                    <div class="form-label">Gambar</div>
                    <label
                      for="upload-image"
                      class="my-2 p-5 border-4 border-dashed rounded-4 w-100 border-light-subtle"
                    >
                      <div class="justify-content-center d-flex">
                        <img
                          :style="
                            imgProduct === null ? 'width: 10%' : 'width: 50%'
                          "
                          :src="
                            imgProduct ||
                            require('@/assets/img/icons/ImageAdd.svg')
                          "
                          alt=""
                        />
                      </div>
                    </label>
                    <Field type="file" name="image" v-slot="{ field }">
                      <input
                        v-bind="field"
                        id="upload-image"
                        ref="fileInput"
                        type="file"
                        class="d-none"
                        accept=".jpeg,.jpg,.png,"
                        @change="onFileChange($event, field)"
                      />
                    </Field>
                    <small
                      v-if="errors.image"
                      :class="errors.image ? 'text-danger' : ''"
                    >
                      {{ errors.image }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nama Produk</label>
                    <Field
                      name="nameProduct"
                      type="text"
                      placeholder="Masukkan Nama Produk anda"
                      class="form-control"
                      :class="{ 'is-invalid': errors.nameProduct }"
                    />
                    <small
                      v-if="errors.nameProduct"
                      :class="errors.nameProduct ? 'text-danger' : ''"
                    >
                      {{ errors.nameProduct }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <div class="d-flex justify-content-between">
                      <label class="form-label">Kategori</label>
                      <button
                        class="btn-link text-blue"
                        style="text-decoration: none"
                        @click="showModal = true"
                      >
                        Tambah Kategori
                      </button>
                    </div>
                    <Field name="category" v-slot="{ field }">
                      <a-select
                        v-bind="field"
                        mode="single"
                        size="large"
                        :class="{ 'is-invalid': errors.category }"
                        :show-search="true"
                        placeholder="Contoh: Jakarta Utara"
                        style="width: 100%"
                        :filter-option="false"
                        :not-found-content="loadingCategory ? undefined : null"
                        :options="categoryList"
                        @search="getCategory()"
                      >
                        <template v-if="loadingCategory" #notFoundContent>
                          <a-spin size="small" />
                        </template>
                      </a-select>
                    </Field>
                    <small
                      v-if="errors.category"
                      :class="errors.category ? 'text-danger' : ''"
                    >
                      {{ errors.category }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Satuan Produk</label>
                    <Field
                      name="unit"
                      type="text"
                      placeholder="Contoh : Pcs"
                      class="form-control"
                      :class="{ 'is-invalid': errors.unit }"
                    />
                    <small
                      v-if="errors.unit"
                      :class="errors.unit ? 'text-danger' : ''"
                    >
                      {{ errors.unit }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">SKU</label>
                    <Field
                      name="sku"
                      type="text"
                      placeholder="Contoh : SKU12345"
                      class="form-control"
                      :class="{ 'is-invalid': errors.sku }"
                    />
                    <small
                      v-if="errors.sku"
                      :class="errors.sku ? 'text-danger' : ''"
                    >
                      {{ errors.sku }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <div class="d-flex justify-content-between">
                      <label for="stock">Stok</label>
                      <a-switch id="stock" v-model:checked="checked" />
                    </div>
                    <div class="my-2">
                      Masukkan limit stok untuk pemberitahuan stok kamu hampir
                      habis
                    </div>
                    <div v-if="checked">
                      <Field
                        name="limitStock"
                        type="number"
                        placeholder="Masukkan limit stok anda 10"
                        class="form-control"
                      />
                      <small class="text-gray"
                        >Pemberitahuan akan muncul saat stok menyentuh angka
                        limit</small
                      >
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Harga</label>
                    <Field
                      name="price"
                      type="number"
                      placeholder="Contoh : 10.000"
                      class="form-control"
                      :class="{ 'is-invalid': errors.price }"
                    />
                    <small
                      v-if="errors.price"
                      :class="errors.price ? 'text-danger' : ''"
                    >
                      {{ errors.price }}
                    </small>
                  </div>
                  <div class="modal-footer-btn">
                    <button type="submit" class="btn btn-submit w-25">
                      Buat Produk
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal-add-category v-model:open="showModal" @update:open="validateModal" />
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { uploadMediaWithToken } from "@/services/global-url";
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";
import ModalAddCategory from "./modal-add-category.vue";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
    ModalAddCategory,
  },
  data() {
    return {
      imgProduct: null,
      categoryList: null,
      loadingCategory: false,
      checked: false,
      showModal: false,
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    async onFileChange(event, field) {
      const file = event.target.files[0];
      try {
        await this.schema.fields.image.validate(file);
        field.onChange(file);
        this.imgProduct = await uploadMediaWithToken(file);
      } catch (error) {
        this.$refs.fileInput.value = "";
      }
    },
    getCategory() {
      const url = "/api/v1/category";
      axiosWithAuth
        .get(url)
        .then((response) => {
          if (response.data.meta.code === 200) {
            this.categoryList = response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
          }
        })
        .catch(() => {
          toast("error", "Gagal mendapatkan list kategori");
        });
    },
    validateModal(newVal) {
      this.showModal = newVal.showModal;
      if (newVal.addCategory) this.getCategory();
    },
    validateSubmitProduct(values) {
      const body = {
        image: this.imgProduct,
        name: values.nameProduct,
        category_id: values.category,
        unit: values.unit,
        sku: values.sku,
        is_stock: this.checked,
        stock_limit: values.limitStock,
        price: values.price,
      };

      this.$router.push({
        path: "/inventory/store-products",
        state: { product: body },
      });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      image: Yup.mixed()
        .required("Upload foto produk kamu.")
        .test(
          "fileSize",
          "Max ukuran gambar 2 MB",
          (value) => value && value.size <= 2 * 1024 * 1024
        )
        .test(
          "fileType",
          "Format gambar harus JPEG, JPG, atau PNG",
          (value) =>
            value &&
            ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
        ),
      nameProduct: Yup.string().required("Nama produk tidak boleh kosong"),
      category: Yup.string().required("Jenis usaha tidak boleh kosong"),
      unit: Yup.string().required("Satuan produk tidak boleh kosong"),
      sku: Yup.string().required("SKU produk tidak boleh kosong"),
      price: Yup.string().required("Harga produk tidak boleh kosong"),
    });
    return {
      schema,
    };
  },
};
</script>
