<template>
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark mb-5"
          src="@/assets/img/logo.png"
          alt="Logo"
        />
        <div class="loginbox">
          <div class="login-right">
            <div class="login-right-wrap">
              <div class="login-info">
                <p class="account-subtitle">Welcome back!</p>
                <img
                  src="@/assets/img/login-user.png"
                  class="img-fluid"
                  alt="User-Img"
                />
                <h5>{{ profile.name }}</h5>
                <h6>({{ profile.email }})</h6>
              </div>

              <Form
                @submit="onSubmit"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="input-blocks">
                  <div class="pass-group">
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control pass-input mt-2 hide-error-icon"
                      placeholder="********"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="{
                          'fas fa-eye': showPassword,
                          'fas fa-eye-slash': !showPassword,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div>
                <button class="btn btn-lg btn-block btn-primary" type="submit">
                  Log In
                </button>
                <div class="w-100 justify-center">
                  <router-link to="/login">
                    <button
                      class="btn btn-link text-center"
                      @click="moveToLogin()"
                    >
                      ganti akun ?
                    </button>
                  </router-link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { axiosNoAuth } from "@/plugins/axios";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  data() {
    return {
      profile: JSON.parse(localStorage.getItem("profile")),
      showPassword: null,
      password: null,
    };
  },
  methods: {
    moveToLogin() {
      localStorage.clear();
      this.$route.push('/login')
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async onSubmit(values) {
      const url = "/api/v1/auth/login";
      const body = {
        email: this.profile.email,
        password: values.password,
      };
      await axiosNoAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            localStorage.setItem("auth", JSON.stringify(response.data.data));
            setTimeout(() => {
              this.$router.go("/dashboard");
            }, 500);
          }
        })
        .catch(() => {
          document.getElementById("password").innerHTML =
            "Kata sandi tidak sesuai";
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      password: Yup.string()
        .min(8, "Minimal kata sandi adalah 8 karakter")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>[\]\/?&;'-])(?=.*[a-zA-Z]).{8,50}$/,
          "Mengandung huruf, angka dan simbol"
        )
        .required("Kata sandi tidak boleh kosong"),
    });
    return {
      schema,
    };
  },
};
</script>
