c
<template>
  <div class="account-page">
    <div class="account-content">
      <div class="login-wrapper register-wrap bg-img">
        <div class="login-content">
          <Form
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors }"
            style="width: 60%"
          >
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="@/assets/img/logo/logo.svg" alt="img" />
              </div>
              <router-link to="/dashboard" class="login-logo logo-white">
                <img src="@/assets/img/logo-white.png" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Daftar Menggunakan Email</h3>
                <h4>
                  Masukkan data berikut untuk mendaftar di aplikasi Kasirkita
                </h4>
              </div>
              <div class="form-login">
                <label>Email</label>
                <div class="form-addons">
                  <Field
                    name="email"
                    type="text"
                    class="form-control hide-error-icon"
                    placeholder="Contoh : email@kasirkita.com"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <div class="emailshow text-danger" id="email"></div>
                  <img src="@/assets/img/icons/mail.svg" alt="img" />
                </div>
              </div>
              <div class="form-login">
                <label>Nomor HP</label>
                <div class="form-addons input-group">
                  <span class="input-group-text" id="basic-addon1">+62</span>
                  <Field
                    name="numberphone"
                    type="text"
                    class="form-control"
                    placeholder="Contoh : 812xxxxxxxx"
                    :class="{ 'is-invalid': errors.numberphone }"
                    @keypress="isNumber($event)"
                  />
                  <img src="@/assets/img/icons/user-icon.svg" alt="img" />
                </div>
                <div v-if="errors.numberphone" class="text-danger fs-12">
                  {{ errors.numberphone }}
                </div>
                <div class="emailshow text-danger" id="numberphone"></div>
              </div>
              <div class="form-login">
                <label>Kata Sandi</label>
                <div class="pass-group">
                  <Field
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control pass-input mt-2 hide-error-icon"
                    placeholder="Masukkan Kata Sandi"
                    :class="{ 'is-invalid': errors.password }"
                    @keypress="NoSpace($event)"
                  />
                  <span @click="toggleShow" class="toggle-password">
                    <i
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                  <div class="emailshow text-danger" id="password"></div>
                </div>
              </div>
              <div class="form-login">
                <label>Konfirmasi Kata Sandi</label>
                <div class="pass-group">
                  <Field
                    name="confirmpassword"
                    :type="showPassword1 ? 'text' : 'password'"
                    class="form-control pass-input mt-2 hide-error-icon"
                    placeholder="Konfirmasi Kata Sandi Anda"
                    :class="{ 'is-invalid': errors.confirmpassword }"
                    @keypress="NoSpace($event)"
                  />
                  <span @click="toggleShow1" class="toggle-password">
                    <i
                      :class="{
                        'fas fa-eye': showPassword1,
                        'fas fa-eye-slash': !showPassword1,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">
                    {{ errors.confirmpassword }}
                  </div>
                  <div class="emailshow text-danger" id="confirmpassword"></div>
                </div>
              </div>
              <div class="form-login authentication-check">
                <div class="row">
                  <div class="col-sm-12">
                    <div
                      class="custom-control custom-checkbox justify-content-start"
                    >
                      <div class="custom-control custom-checkbox">
                        <label class="checkboxs">
                          <Field
                            name="terms"
                            type="checkbox"
                            :value="false"
                            :class="{ 'is-invalid': errors.terms }"
                          />
                          <span
                            class="checkmarks"
                            :style="errors.terms ? 'border-color: red;' : ''"
                          ></span
                          >Dengan ini saya menyatakan telah membaca & menyetujui
                          <a
                            href="javascript:void(0);"
                            class="hover-a text-primary"
                            >Ketentuan Layanan</a
                          >
                          dan
                          <a
                            href="javascript:void(0);"
                            class="hover-a text-primary"
                            >Kebijakan Kasirkita</a
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-login">
                <button type="submit" class="btn btn-login">Daftar</button>
              </div>
              <div class="signinform">
                <h4>
                  Sudah mempunyai akun?
                  <router-link to="/login" class="hover-a">Masuk</router-link>
                </h4>
              </div>
            </div>
          </Form>
        </div>
        <div class="login-img">
          <img src="@/assets/img/authentication/register02.png" alt="img" />
        </div>
      </div>
    </div>

    <otp-input
      :open-modal="showModal"
      :email="email"
      usage="register"
      title="Verifikasi Email"
      :description="`Masukkan kode OTP yang telah kami kirim ke email anda ${email}`"
      @success-validate="login()"
    />
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import otpInput from "@/components/modal/otp-input/otpInput.vue";
import { axiosNoAuth } from "@/plugins/axios";
import { isNumber, NoSpace } from "@/utils/libs";
import { toast } from "@/components/modal/toast-message/toastMessage";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
    otpInput,
  },
  data() {
    return {
      showPassword: false,
      showPassword1: false,
      showModal: false,
      checkBox: false,
      email: "",
      password: "",
      toast,
      isNumber,
      NoSpace,
    };
  },
  computed: {},
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    onSubmit(values) {
      this.email = values.email;
      this.password = values.password;
      const url = "/api/v1/auth/register";
      const body = {
        email: values.email,
        phone: `0${values.numberphone}`,
        password: values.password,
      };
      axiosNoAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            this.showModal = true;
          }
        })
        .catch(() => {
          document.getElementById("email").innerHTML = "Email tidak sesuai";
          document.getElementById("numberphone").innerHTML =
            "Nomor HP tidak sesuai";
        });
    },
    login() {
      this.showModal = false;
      const url = "/api/v1/auth/login";
      const body = {
        email: this.email,
        password: this.password,
      };
      axiosNoAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            localStorage.setItem("auth", JSON.stringify(response.data.data));
            this.$router.go("/dashboard");
          }
        })
        .catch(() => {
          toast("error", "Failed login");
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email tidak boleh kosong")
        .email("Format email tidak valid"),
      numberphone: Yup.string()
        .required("Nomor hp tidak boleh kosong")
        .min(9, "Nomor hp minimal 9 nomor")
        .max(13, "Nomor hp maksimal 13 nomor"),
      password: Yup.string()
        .min(8, "Minimal kata sandi adalah 8 karakter")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>[\]\/?&;'-])(?=.*[a-zA-Z]).{8,50}$/,
          "Mengandung huruf, angka dan simbol"
        )
        .required("Kata sandi tidak boleh kosong"),
      confirmpassword: Yup.string()
        .min(8, "Minimal kata sandi adalah 8 karakter")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>[\]\/?&;'-])(?=.*[a-zA-Z]).{8,50}$/,
          "Mengandung huruf, angka dan simbol"
        )
        .required("Konfirmasi Kata sandi tidak boleh kosong")
        .test("passwords-match", "Kata sandi tidak sesuai", function (value) {
          return value === this.parent.password;
        }),
      terms: Yup.boolean(true).required(),
    });

    return {
      schema,
    };
  },
};
</script>
<style scoped>
.bg-none {
  background-image: none;
}
</style>
