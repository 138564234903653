<template>
  <div class="account-page">
    <div class="account-content">
      <div class="login-wrapper login-new">
        <div class="container">
          <div class="login-content user-login">
            <div class="login-logo">
              <img src="@/assets/img/logo/logo.svg" alt="img" />
              <router-link to="/dashboard" class="login-logo logo-white">
                <img src="@/assets/img/logo-white.png" alt="" />
              </router-link>
            </div>
            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <div class="login-userset">
                <div class="login-userheading text-center">
                  <h3>Masuk Menggunakan Email</h3>
                  <h4>
                    Masukkan akun email anda untuk memulai kembali menggunakan
                    aplikasi Kasirkita
                  </h4>
                </div>
                <div class="form-login">
                  <label class="form-label">Email</label>
                  <div class="form-addons">
                    <Field
                      name="email"
                      type="text"
                      placeholder="example@kasirkita.com"
                      class="form-control hide-error-icon"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <div class="invalid-feedback">{{ errors.email }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                    <img src="@/assets/img/icons/mail.svg" alt="img" />
                  </div>
                </div>
                <div class="form-login">
                  <label>kata Sandi</label>
                  <div class="pass-group">
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control pass-input mt-2 hide-error-icon"
                      placeholder="********"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="{
                          'fas fa-eye': showPassword,
                          'fas fa-eye-slash': !showPassword,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div>
                <div class="form-login authentication-check">
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-end">
                      <router-link class="forgot-link" to="/forgot-password"
                        >Lupa Kata Sandi?</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="form-login">
                  <button class="btn btn-login" type="submit">Login</button>
                </div>
                <div class="signinform text-center">
                  <h4>
                    Belum mempunyai akun?<router-link
                      to="/register"
                      class="hover-a"
                    >
                      Daftar</router-link
                    >
                  </h4>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { axiosNoAuth } from "@/plugins/axios";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      emailError: "",
      passwordError: "",
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    onSubmit(values) {
      const url = "/api/v1/auth/login";
      const body = {
        email: values.email,
        password: values.password,
      };
      axiosNoAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            localStorage.setItem("auth", JSON.stringify(response.data.data));
            setTimeout(() => {
              this.$router.go("/dashboard");
            }, 500);
          }
        })
        .catch(() => {
          document.getElementById("email").innerHTML = "Email tidak sesuai";
          document.getElementById("password").innerHTML =
            "Kata sandi tidak sesuai";
        });
    },
  },
  beforeUnmount() {
    clearTimeout();
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email tidak boleh kosong")
        .email("Format email tidak valid"),
      password: Yup.string()
        .min(8, "Minimal kata sandi adalah 8 karakter")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>[\]\/?&;'-])(?=.*[a-zA-Z]).{8,50}$/,
          "Mengandung huruf, angka dan simbol"
        )
        .required("Kata sandi tidak boleh kosong"),
    });
    return {
      schema,
    };
  },
};
</script>
