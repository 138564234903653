import { createRouter, createWebHistory } from "vue-router";

// authentication
import Register from "@/views/pages/authentication/register/pos-register.vue";
import SignIn_3 from "@/views/pages/authentication/login/signin-3.vue";
import Forgot_Password_2 from "@/views/pages/authentication/forgot-password/forgot-password-2.vue";
import Reset_Password_2 from "@/views/pages/authentication/reset-password/reset-password-2.vue";
import SuccessTwo from "@/views/pages/authentication/success-2.vue";
import Lock_Screen from "@/views/pages/authentication/lock-screen.vue";

// error pages
import Under_Maintenance from "@/views/pages/error/under-maintenance.vue";
import Error from "@/views/pages/error/pos-error.vue";
import Error_404 from "@/views/pages/error/error-404.vue";
import Error_500 from "@/views/pages/error/error-500.vue";

// Apps
import AdminDashboard from "@/views/pages/dashboard/admin-dashboard.vue";
import Subscribe from "@/views/pages/subscribe/subscribe-users.vue";
import Add_Merchant from "@/views/pages/merchant/add-merchant.vue";
import Onboarding from "@/views/pages/dashboard/onboarding-merchant.vue";

import Inventory from "@/views/pages/inventory/pos-inventory.vue";
import Add_Product from "@/views/pages/inventory/add-product.vue";
import Store_Product from "@/views/pages/inventory/store-product.vue";
import Edit_Product from "@/views/pages/inventory/edit-product.vue";
import Product_Details from "@/views/pages/inventory/product-details.vue";
import Product_List from "@/views/pages/inventory/product-list/product-list.vue";

import Stock from "@/views/pages/stock/pos-stock.vue";
import ManageStocks from "@/views/pages/stock/manage-stocks.vue";
import StockAdjustment from "@/views/pages/stock/stock-adjustment.vue";

import Sales from "@/views/pages/sales/pos-sales.vue";
import SalesList from "@/views/pages/sales/sales-list.vue";
import SalesReturns from "@/views/pages/sales/sales-returns.vue";

import Reports from "@/views/pages/reports/pos-reports.vue";
import Sales_Report from "@/views/pages/reports/sales-report.vue";
import Purchase_Report from "@/views/pages/reports/purchase-report.vue";
import POS from "@/views/pages/sales/sales-pos.vue";

import Profile from "@/views/pages/profile/profile-merchant.vue";
import AddStore from "@/views/pages/store/add-store.vue";
import StoreProducts from "@/views/pages/store/product-store.vue";
import Store from "@/views/pages/store/store.vue";
import CheckPayment from "@/views/pages/subscribe/check-payment.vue";

const profile = localStorage.getItem('profile')
const auth = localStorage.getItem('auth')

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: { requiresAuth: false }
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: SignIn_3,
  },
  {
    path: "/register",
    name: "register",
    meta: { requiresAuth: false },
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { requiresAuth: false },
    component: Forgot_Password_2,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: { requiresAuth: false },
    component: Reset_Password_2,
  },
  {
    path: "/success",
    name: "success",
    meta: { requiresAuth: false },
    component: SuccessTwo,
  },
  {
    path: "/lock-screen",
    name: "lock-screen",
    meta: { requiresAuth: false },
    component: Lock_Screen,
  },
  {
    path: "/profile",
    name: "profile",
    meta: { requiresAuth: true },
    component: Profile,
  },
  {
    path: "/store",
    meta: { requiresAuth: true },
    component: Store,
    children: [
      { path: "", redirect: "/store/add-store" },
      { path: "add-store", component: AddStore },
      { path: "products-store/:storeId", name: "products-store", component: StoreProducts },
    ],
  },
  {
    path: "/error",
    meta: { requiresAuth: true },
    component: Error,
    children: [
      { path: "", redirect: "/error/error-404" },
      { path: "error-404", component: Error_404 },
      { path: "error-500", component: Error_500 },
      { path: "under-maintenance", component: Under_Maintenance },
    ],
  },
  {
    path: "/inventory",
    meta: { requiresAuth: true },
    component: Inventory,
    children: [
      { path: "", redirect: "/inventory/product-list" },
      { path: "product-list", component: Product_List },
      { path: "add-product", component: Add_Product },
      { path: "store-products", component: Store_Product },
      { path: "edit-product", component: Edit_Product },
      { path: "product-details", component: Product_Details },
    ],
  },
  {
    path: "/reports",
    meta: { requiresAuth: true },
    component: Reports,
    children: [
      { path: "", redirect: "/reports/sales-report" },
      { path: "sales-report", component: Sales_Report },
      { path: "purchase-report", component: Purchase_Report },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { requiresAuth: true },
    component: AdminDashboard,
  },
  { 
    path: "/onboarding", 
    name: "onboarding",
    meta: { requiresAuth: true },
    component: Onboarding 
  },
  {
    path: "/subscribe",
    name: "subscribe",
    meta: { requiresAuth: true },
    component: Subscribe,
  },
  { path: "/subscribe/payment",
    name: "payment",
    meta: { requiresAuth: true },
    component: CheckPayment 
  },
  {
    path: "/merchant",
    name: "merchant",
    meta: { requiresAuth: true },
    component: Add_Merchant,
  },
  {
    path: "/stock",
    meta: { requiresAuth: true },
    component: Stock,
    children: [
      { path: "", redirect: "/stock/manage-stocks" },
      { path: "manage-stocks", component: ManageStocks },
      { path: "stock-adjustment", component: StockAdjustment },
    ],
  },
  {
    path: "/sales",
    meta: { requiresAuth: true },
    component: Sales,
    children: [
      { path: "", redirect: "/sales/sales-list" },
      { path: "sales-list", component: SalesList },
      { path: "sales-returns", component: SalesReturns },
      { path: "pos", component: POS },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    return next('/error')
  }

  if (to.meta.requiresAuth) {
    if (auth === null) {
      next('/login')
    } else if (to.name === 'dashboard') {
      next()
    } else if (to.name !== 'dashboard' && to.name !== 'merchant' && profile.merchant === null) {
      next('/merchant')
    } else {
      next()
    }
  } else {
    if (auth) {
      next('/dashboard')
    } else {
      next()
    }
  }
});
