<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Pilih Toko & Stok Produk</h4>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="page-wrapper-new p-0">
            <div class="content">
              <div class="justify-content-between mb-5">
                <div class="fw-bold mb-2 d-flex justify-content-between">
                  <h4>Pilih Toko</h4>
                  <div class="d-flex">
                    <label for="checkedAll" class="mr-2">Semua</label>
                    <a-checkbox
                      id="checkedAll"
                      v-model:checked="checked"
                      @change="selectAll"
                    ></a-checkbox>
                  </div>
                </div>
                <div>
                  Pilih toko untuk menjual produk anda dan atur stoknya
                </div>
              </div>
              <div
                v-for="item in listStore"
                :key="item.id"
                class="row border rounded-4 py-3"
              >
                <div
                  class="col-2 d-flex justify-content-center align-self-baseline"
                >
                  <img class="w-50" src="@/assets/img/icons/Store.svg" alt="" />
                </div>
                <div class="col-9">
                  <h4>{{ item.name }}</h4>
                  <div class="my-2">{{ item.address }}</div>
                  <label>Stok</label>
                  <input
                    v-if="product"
                    v-model="item.stock"
                    type="number"
                    placeholder="Masukkan Stok"
                    class="form-control my-2"
                    :disabled="!product.is_stock"
                  />
                </div>
                <div class="col-1 d-flex justify-content-end align-self-start">
                  <input type="checkbox" v-model="item.selected">
                </div>
              </div>
              <div class="justify-content-end d-flex my-5">
                <button @click="submitForm" class="btn btn-primary">
                  Lanjutkan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { storeList } from "@/services/global-url";
import { getListStore } from "@/utils/storage";
import { toast } from "@/components/modal/toast-message/toastMessage";
import { axiosWithAuth } from "@/plugins/axios";
import { useRouter } from "vue-router";

export default {
  setup() {
    const listStore = reactive([]);
    const checked = ref(false);
    const product = ref(window.history.state.product);
    const router = useRouter();

    const getStore = async () => {
      try {
        await storeList();
        const listStoreArray = getListStore;
        listStoreArray.map((item) => {
          listStore.push({
            ...item,
            stock: 0,
            selected: false,
          });
        });
      } catch (error) {
        console.error(error)
      }
    };

    const selectAll = () => {
      listStore.forEach((item) => {
        item.selected = checked.value;
      });
    };

    const submitForm = () => {
      const selectedStores = listStore
        .filter((store) => store.selected)
        .map((store) => ({
          store_id: store.id,
          stock: store.stock,
        }));

      const body = {
        ...product.value,
        stores: selectedStores,
      };

      const url = "/api/v1/product";
      axiosWithAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            toast("success", "Berhasil membuat produk");
            router.push("/onboarding");
          }
        })
        .catch(() => {
          toast("error", "Gagal membuat produk");
        });
    };

    onMounted(() => {
      getStore();
    });

    return {
      listStore,
      checked,
      product,
      submitForm,
      selectAll,
    };
  },
};
</script>
