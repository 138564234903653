<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="justify-content-between d-flex w-100">
          <div class="page-title align-self-center">
            <h4>Lengkapi Profil</h4>
          </div>
          <ul class="table-top-head">
            <li>
              <div class="page-btn">
                <router-link to="/onboarding" class="btn btn-secondary">
                  <vue-feather type="arrow-left" class="me-2"></vue-feather
                  >Kembali ke Onboarding
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="page-wrapper-new p-0">
            <div class="content">
              <Form
                @submit="submitForm"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="justify-content-center d-flex mb-5">
                  <label for="upload-image">
                    <div class="justify-content-center d-flex">
                      <img
                        :src="
                          imgStore ||
                          require('@/assets/img/icons/profile-upload.svg')
                        "
                        alt=""
                      />
                    </div>
                    <div class="text-center mt-5" style="color: #bababa">
                      <div
                        :class="
                          errors.image === 'required' ? 'text-danger' : ''
                        "
                      >
                        Upload logo untuk usaha kamu.
                      </div>
                      <div
                        :class="errors.image === 'maxsize' ? 'text-danger' : ''"
                      >
                        Max ukuran gambar 2 MB
                      </div>
                      <div
                        v-if="errors.image === 'extension'"
                        class="text-danger"
                      >
                        Format gambar harus JPEG, JPG, atau PNG
                      </div>
                    </div>
                  </label>
                  <Field type="file" name="image" v-slot="{ field }">
                    <input
                      v-bind="field"
                      id="upload-image"
                      ref="fileInput"
                      type="file"
                      class="d-none"
                      accept=".jpeg,.jpg,.png,"
                      @change="onFileChange($event, field)"
                    />
                  </Field>
                </div>
                <div class="justify-content-between mb-5">
                  <div class="page-title fw-bold">
                    <h4>Data Diri</h4>
                  </div>
                </div>
                <div class="new-employee-field">
                  <div class="mb-3">
                    <label class="form-label">Nama Pemilik</label>
                    <Field
                      name="owner"
                      v-model="owner"
                      type="text"
                      placeholder="Contoh : Ajis Setiawan"
                      class="form-control"
                      :class="{ 'is-invalid': errors.owner }"
                    />
                    <small v-if="errors.owner" class="text-danger">
                      {{ errors.owner }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nama Usaha</label>
                    <Field
                      name="merchant"
                      v-model="merchant"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.merchant }"
                      placeholder="Contoh : Toko Serbaguna"
                    />
                    <small v-if="errors.merchant" class="text-danger">
                      {{ errors.merchant }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Jenis Usaha</label>
                    <Field
                      name="businessType"
                      v-model="businessType"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.businessType }"
                      disabled
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Lokasi Usaha</label>
                    <Field v-model:value="city" name="city" v-slot="{ field }">
                      <a-select
                        v-bind="field"
                        mode="single"
                        size="large"
                        :class="{ 'is-invalid': errors.city }"
                        :show-search="true"
                        placeholder="Contoh: Jakarta Utara"
                        style="width: 100%"
                        :filter-option="false"
                        :not-found-content="loadingCity ? undefined : null"
                        :options="cityList"
                        @search="getCity"
                      >
                        <template v-if="loadingCity" #notFoundContent>
                          <a-spin size="small" />
                        </template>
                      </a-select>
                    </Field>
                    <small v-if="cityError !== ''" class="text-danger">{{
                      cityError
                    }}</small>
                    <small v-if="errors.city" class="text-danger">
                      {{ errors.city }}
                    </small>
                    <small class="emailshow text-danger" id="city"></small>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Detail Alamat</label>
                    <Field
                      name="detailAddress"
                      v-model="address"
                      v-slot="{ field }"
                    >
                      <textarea
                        v-bind="field"
                        class="form-control"
                        :class="{ 'is-invalid': errors.detailAddress }"
                        placeholder="Contoh : jl xxx gang xxx no xxx"
                      />
                    </Field>
                    <small v-if="errors.detailAddress" class="text-danger">
                      {{ errors.detailAddress }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <div class="mb-3 mt-5 fs-20 fw-bold">Pajak</div>
                    <label class="form-label">Tambahkan Pajak</label>
                    <Field
                      name="tax"
                      v-model="tax"
                      type="number"
                      class="form-control"
                      :class="{ 'is-invalid': errors.tax }"
                      placeholder="Masukkan 0% jika tidak ada pajak toko"
                    />
                    <small class="text-danger"
                      >Pajak ini akan digunakan untuk semua produk di toko
                      anda</small
                    >
                  </div>
                  <div class="modal-footer-btn">
                    <button type="submit" class="btn btn-submit w-25">
                      Simpan
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { getProfile } from "@/utils/storage";
import { uploadMediaWithToken } from "@/services/global-url";
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  data() {
    return {
      imgStore: null,
      owner: getProfile.name,
      merchant: getProfile.merchant.name,
      businessType: getProfile.merchant.business_type,
      city: getProfile.merchant.city,
      address: getProfile.merchant.address,
      tax: getProfile.merchant.tax,
      cityList: [],
      cityError: "",
      loadingCity: false,
    };
  },
  methods: {
    async onFileChange(event, field) {
      const file = event.target.files[0];
      try {
        await this.schema.fields.image.validate(file);
        field.onChange(file);
        this.imgStore = await uploadMediaWithToken(file);
      } catch (error) {
        this.$refs.fileInput.value = "";
      }
    },
    getCity(search) {
      this.loadingCity = true;
      if (search.length >= 3) {
        this.cityError = "";
        const url = `/api/v1/merchant/city?search=${search}`;
        axiosWithAuth
          .get(url)
          .then((response) => {
            this.loadingCity = false;
            if (response.data.data !== null) {
              this.cityList = response.data.data.map((item) => ({
                value: item.id,
                label: item.name,
              }));
            }
          })
          .catch(() => {
            toast("error");
            this.loadingCity = false;
          });
      } else {
        this.cityError = "minimal 3 karakter";
      }
    },
    submitForm(values) {
      const url = "/api/v1/auth/profile";
      const body = {
        owner_name: values.owner,
        merchant: {
          logo: this.imgStore,
          name: values.merchant,
          business_type: values.businessType,
          city: values.city,
          address: values.detailAddress,
          tax: values.tax,
        },
      };
      axiosWithAuth
        .put(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            toast("success", "Berhasil update data profil");
            this.$router.push("/onboarding");
          }
        })
        .catch(() => {
          toast("error", "Gagal update data profil");
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      image: Yup.mixed()
        .required("required")
        .test(
          "fileSize",
          "maxsize",
          (value) => value && value.size <= 2 * 1024 * 1024 // 2 MB
        )
        .test(
          "fileType",
          "extension",
          (value) =>
            value &&
            ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
        ),
      owner: Yup.string().required("Nama pemilik tidak boleh kosong"),
      merchant: Yup.string().required("Nama usaha tidak boleh kosong"),
      businessType: Yup.string().required("Jenis usaha tidak boleh kosong"),
      city: Yup.string().required("Kota tidak boleh kosong"),
      detailAddress: Yup.string().required("Detail alamat tidak boleh kosong"),
      tax: Yup.string().required("Pajak tidak boleh kosong"),
    });
    return {
      schema,
    };
  },
};
</script>
