<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="justify-content-between d-flex w-100">
          <div class="page-title align-self-center">
            <h4>Buat Toko</h4>
          </div>
          <ul class="table-top-head">
            <li>
              <div class="page-btn">
                <router-link to="/onboarding" class="btn btn-secondary">
                  <vue-feather type="arrow-left" class="me-2"></vue-feather
                  >Kembali ke Onboarding
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="page-wrapper-new p-0">
            <div class="content">
              <Form
                @submit="validateSubmitStore"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="justify-content-between mb-5">
                  <div class="page-title fw-bold">
                    <h4>Data Toko</h4>
                  </div>
                </div>
                <div class="new-employee-field">
                  <div class="mb-3">
                    <label class="form-label">Nama Toko</label>
                    <Field
                      name="namestore"
                      type="text"
                      placeholder="Contoh : Ajis Setiawan"
                      class="form-control"
                      :class="{ 'is-invalid': errors.namestore }"
                    />
                    <small v-if="errors.namestore" class="text-danger">
                      {{ errors.namestore }}
                    </small>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Jenis Usaha</label>
                    <Field
                      name="businessType"
                      v-model="businessType"
                      type="text"
                      class="form-control"
                      disabled
                    />
                  </div>
                </div>
                <div class="justify-content-between my-5">
                  <div class="page-title fw-bold">
                    <h4>Alamat Toko</h4>
                  </div>
                </div>
                <div class="new-employee-field">
                  <div class="mb-3">
                    <label class="form-label">Detail Alamat</label>
                    <Field
                      name="detailAddress"
                      v-model="address"
                      v-slot="{ field }"
                    >
                      <textarea
                        v-bind="field"
                        v-model="address"
                        class="form-control"
                        :class="{ 'is-invalid': errors.detailAddress }"
                        placeholder="Contoh : jl xxx gang xxx no xxx"
                      />
                    </Field>
                    <small v-if="errors.detailAddress" class="text-danger">
                      {{ errors.detailAddress }}
                    </small>
                  </div>
                </div>
                <div class="justify-content-between my-5">
                  <div class="page-title fw-bold">
                    <h4>Printer</h4>
                  </div>
                </div>
                <div class="new-employee-field">
                  <div class="mb-3">
                    <label class="form-label">Printer</label>
                    <Field name="printer" v-slot="{ field }">
                      <a-radio-group v-bind="field" class="d-flex gap-5">
                        <div
                          class="px-4 py-3 border border-1 rounded-pill"
                          :class="{
                            'bg-selected': field.value === 'thermal',
                          }"
                        >
                          <a-radio value="thermal"
                            ><span
                              :class="
                                field.value === 'thermal'
                                  ? 'text-primary'
                                  : 'text-gray'
                              "
                              >Thermal Printer</span
                            ></a-radio
                          >
                        </div>
                        <div
                          class="px-4 py-3 border border-1 rounded-pill"
                          :class="{
                            'bg-selected': field.value === 'dot_matrix',
                          }"
                        >
                          <a-radio value="dot_matrix"
                            ><span
                              :class="
                                field.value === 'dot_matrix'
                                  ? 'text-primary'
                                  : 'text-gray'
                              "
                              >Dot Matrix Printer</span
                            ></a-radio
                          >
                        </div>
                      </a-radio-group>
                    </Field>
                    <small v-if="errors.printer" class="text-danger">
                      {{ errors.printer }}
                    </small>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button type="submit" class="btn btn-submit w-25">
                    Buat Toko
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";
import { getProfile, getListStore } from "@/utils/storage";
import { storeList } from "@/services/global-url";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  data() {
    return {
      businessType: getProfile.merchant.business_type,
      listStore: getListStore,
    };
  },
  async mounted() {
    await storeList();
  },
  methods: {
    validateSubmitStore(values) {
      const body = {
        name: values.namestore,
        address: values.detailAddress,
        printer_type: values.printer,
      };

      if (this.listStore === null) {
        this.submitForm(body)
      } else {
        this.$router.push({ name: 'products-store', params: { storeId: 1 }, state: { store: body } })
      }
    },
    submitForm(body) {
      const url = "/api/v1/store";
      axiosWithAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            toast("success", "Berhasil membuat toko");
            this.$router.push("/onboarding");
          }
        })
        .catch(() => {
          toast("error", "Gagal membuat toko");
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      namestore: Yup.string().required("Nama Toko tidak boleh kosong"),
      detailAddress: Yup.string().required("Detail alamat tidak boleh kosong"),
      printer: Yup.string().required("Wajib memilih salah satu printer."),
    });
    return {
      schema,
    };
  },
};
</script>
<style scoped>
.bg-selected {
  background-color: #e9e5f1;
}
</style>
