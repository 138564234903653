<template>
  <div class="account-page">
    <div class="account-content">
      <div class="login-wrapper bg-img">
        <div class="login-content">
          <Form
            @submit="submitForm"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="@/assets/img/logo/logo.svg" alt="img" />
              </div>
              <router-link to="/dashboard" class="login-logo logo-white">
                <img src="@/assets/img/logo-white.png" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Buat Kata Sandi</h3>
                <h4>
                  Buat kata sandi baru anda. Pastikan kata sandi lebih dari 8
                  karakter dan jangan berikan kata sandi kepada siapapun
                </h4>
              </div>
              <div class="form-login">
                <label>Kata Sandi</label>
                <div class="pass-group">
                  <Field
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control pass-input mt-2 hide-error-icon"
                    placeholder="Masukkan Kata Sandi Anda"
                    :class="{ 'is-invalid': errors.password }"
                    @keypress="NoSpace($event)"
                  />
                  <span @click="toggleShow" class="toggle-password">
                    <i
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></i>
                  </span>
                  <div v-if="errors.password" class="invalid-feedback">
                    {{ errors.password }}
                  </div>
                  <div class="emailshow text-danger" id="password"></div>
                </div>
              </div>
              <div class="form-login">
                <label>Konfirmasi Kata Sandi</label>
                <div class="pass-group">
                  <Field
                    name="confirmpassword"
                    :type="showPassword1 ? 'text' : 'password'"
                    class="form-control pass-input mt-2 hide-error-icon"
                    placeholder="Konfirmasi Kata Sandi Anda"
                    :class="{ 'is-invalid': errors.confirmpassword }"
                  />
                  <span @click="toggleShow1" class="toggle-password">
                    <i
                      :class="{
                        'fas fa-eye': showPassword1,
                        'fas fa-eye-slash': !showPassword1,
                      }"
                    ></i>
                  </span>
                  <div v-if="errors.confirmpassword" class="invalid-feedback">
                    {{ errors.confirmpassword }}
                  </div>
                  <div class="emailshow text-danger" id="confirmpassword"></div>
                </div>
              </div>
              <div class="form-login">
                <button type="submit" class="btn btn-login">
                  Konfirmasi Kata Sandi
                </button>
              </div>
              <div class="signinform text-center">
                <h4>
                  Kembali ke<router-link to="/login" class="hover-a">
                    login
                  </router-link>
                </h4>
              </div>
            </div>
          </Form>
        </div>
        <div class="login-img">
          <img src="@/assets/img/authentication/reset02.png" alt="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { axiosNoAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";
import { NoSpace } from "@/utils/libs";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      showPassword1: false,
      NoSpace,
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    submitForm(values) {
      const url = "/api/v1/auth/reset-password";
      const body = {
        token: sessionStorage.getItem("token"),
        password: values.confirmpassword,
      };
      axiosNoAuth
        .put(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            this.$router.push("/success");
          }
        })
        .catch(() => {
          toast("error");
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      password: Yup.string()
        .min(8, "Minimal kata sandi adalah 8 karakter")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>[\]\/?&;'-])(?=.*[a-zA-Z]).{8,50}$/,
          "Mengandung huruf, angka dan simbol"
        )
        .required("Kata sandi tidak boleh kosong"),
      confirmpassword: Yup.string()
        .min(8, "Minimal kata sandi adalah 8 karakter")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>[\]\/?&;'-])(?=.*[a-zA-Z]).{8,50}$/,
          "Mengandung huruf, angka dan simbol"
        )
        .required("Konfirmasi Kata sandi tidak boleh kosong")
        .test("passwords-match", "Kata sandi tidak sesuai", function (value) {
          return value === this.parent.password;
        }),
    });

    return {
      schema,
    };
  },
};
</script>
