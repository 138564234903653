<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="justify-content-between d-flex w-100">
          <div class="page-title align-self-center">
            <h4>Lengkapi Data</h4>
          </div>
          <ul class="table-top-head">
            <li>
              <div class="page-btn">
                <router-link to="/dashboard" class="btn btn-secondary">
                  <vue-feather type="arrow-left" class="me-2"></vue-feather>
                  Kembali ke Dashboard
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="page-wrapper-new p-0">
            <div class="content">
              <div class="justify-content-between">
                <div class="page-title mb-10">
                  <h4>
                    Lengkapi data bisnis anda untuk kemudahan aplikasi Kasirkita
                  </h4>
                  <div class="my-4">
                    Isi data merchant anda agar transaksi lebih lancar. Mulai
                    dari lengkapi profil, tambah toko, dan masukkan produk,
                    hingga printer yang anda gunakan
                  </div>
                </div>

                <div
                  v-for="item in onboardList"
                  :key="item.name"
                  class="card mb-5 rounded shadow hover-a cursor-pointer"
                  :class="{ 'card-disabled': item.name === 'product' && !isStoreCompleted }"
                  @click="handleClick(item)"
                  style="max-width: 540px"
                >
                  <div
                    class="row g-0"
                    :class="item.status ? 'bg-primary' : ''"
                  >
                    <div class="col-md-2 p-2">
                      <img
                        v-if="item.name === 'profile'"
                        :src="
                          item.status
                            ? require(`@/assets/img/icons/CheckCircleWhite.svg`)
                            : require(`@/assets/img/icons/profile-onboarding.svg`)
                        "
                        class="img-fluid w-100 rounded-start"
                        :alt="item.alt"
                      />
                      <img
                        v-if="item.name === 'store'"
                        :src="
                          item.status
                            ? require(`@/assets/img/icons/CheckCircleWhite.svg`)
                            : require(`@/assets/img/icons/store-onboarding.svg`)
                        "
                        class="img-fluid w-100 rounded-start"
                        :alt="item.alt"
                      />
                      <img
                        v-if="item.name === 'product'"
                        :src="
                          item.status
                            ? require(`@/assets/img/icons/CheckCircleWhite.svg`)
                            : require(`@/assets/img/icons/product-onboarding.svg`)
                        "
                        class="img-fluid w-100 rounded-start"
                        :alt="item.alt"
                      />
                    </div>
                    <div class="col-md-8 align-self-center">
                      <div class="">
                        <h5 v-if="item.status" class="card-title">
                          {{ item.titleActive }}
                        </h5>
                        <h5 v-else class="card-title">
                          {{ item.titleInactive }}
                        </h5>
                      </div>
                    </div>
                    <div class="col-md-2 p-2 align-self-center text-end">
                      <vue-feather
                        type="chevron-right"
                        class="me-2"
                      ></vue-feather>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const onboardList = ref([
  {
    name: "profile",
    alt: "profile-onboarding",
    titleInactive: "Lengkapi data profil anda",
    titleActive: "Data profile kamu telah lengkap",
    route: "/profile",
    status: false,
  },
  {
    name: "store",
    alt: "Buat toko anda",
    titleInactive: "Lengkapi data toko anda",
    titleActive: "Toko berhasil dibuat",
    route: "/store/add-store",
    status: false,
  },
  {
    name: "product",
    alt: "product-onboarding",
    titleInactive: "Buat produk anda",
    titleActive: "Produk berhasil dibuat",
    route: "/inventory/add-product",
    status: false,
  },
]);

const getList = async () => {
  const url = "/api/v1/onboarding";
  await axiosWithAuth
    .get(url)
    .then((response) => {
      const { data } = response.data;
      if (data !== null) {
        const fields = ["profile", "store", "product"];
        onboardList.value.forEach((item) => {
          fields.forEach((field) => {
            if (item.name === field) {
              item.status = data[field] === "completed";
            }
          });
        });
      }
    })
    .catch(() => {
      toast("error");
    });
};

const isStoreCompleted = computed(() => {
  const storeItem = onboardList.value.find((item) => item.name === "store");
  return storeItem?.status || false;
});

const handleClick = (item) => {
  if (item.status) {
    toast("info", "Halaman ini sudah selesai");
    return;
  }
  if (item.name === "product" && !isStoreCompleted.value) {
    toast("error", "Harap lengkapi data toko terlebih dahulu");
    return;
  }
  router.push(item.route); // Use router navigation for valid routes.
};

onMounted(async () => {
  await getList();
});
</script>

<style scoped>
.bg-primary {
  background-color: #6b50a0 !important;
}

.card-disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
