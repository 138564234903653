<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Pilih Produk</h4>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="page-wrapper-new p-0">
            <div class="content">
              <div class="mb-5 d-flex gap-5">
                <a-button type="primary" ghost shape="round">
                  <template #icon>
                    <DownloadOutlined />
                  </template>
                  Semua
                </a-button>
              </div>
              <div class="row border rounded-4 p-2">
                <div
                  class="col-2 d-flex justify-content-center align-self-baseline"
                >
                  <img class="w-50" src="@/assets/img/icons/Store.svg" alt="" />
                </div>
                <div class="col-9">
                  <h4>Kopi Gula Aren</h4>
                  <input
                    type="text"
                    placeholder="Masukkan Stok"
                    class="form-control my-2"
                  />
                  <input
                    type="text"
                    placeholder="Masukkan Limit Stok"
                    class="form-control my-2"
                  />
                </div>
                <div class="col-1 d-flex justify-content-end align-self-start">
                  <a-checkbox
                    id="checkedAll"
                    v-model:checked="checked"
                  ></a-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";

export default {
  data() {
    return {
      limit: 10,
      offset: 0,
    };
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    getProduct() {
      const url = "/api/v1/product";
      const body = {
        store_id: this.$route.params.storeId,
        limit: this.limit,
        offset: this.offset
      }

      const queryString = new URLSearchParams(body).toString();

      axiosWithAuth
        .get(`${url}?${queryString}`)
        .then((response) => {
          if (response.data.meta.code === 200) {
            console.log(response.data.data)
          }
        })
        .catch(() => {
          toast("error", "");
        });
    },
  },
};
</script>
