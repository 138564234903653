import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";
import { router } from "@/router";
import { getAuth, setAuth, setProfile, setListStore } from "../utils/storage";
import { setCookie } from "../utils/libs";

export const getProfileApi = async () => {
  const url = "/api/v1/auth/profile";
  await axiosWithAuth
    .get(url)
    .then((response) => {
      setProfile(response.data.data);
      if (response.data.data.merchant === null) {
        router.push("/merchant");
      }
    })
    .catch(() => {
      toast("error");
    });
};

export const subscription = async () => {
  const url = "/api/v1/subscription";
  await axiosWithAuth
    .get(url)
    .then((response) => {
      if (response.data.meta.code === 200) {
        if (response.data.data !== null) {
          const expired = Math.floor((new Date(response.data.data.expired_at) - new Date())/(1000 * 60 * 60 * 24))
          setCookie('subscription', response.data.data.plan_name, expired)
        } else {
          router.push("/subscribe")
        }
      }
    })
    .catch(() => {
      toast("error");
    });
};

export const refreshToken = async (lastApi) => {
  const url = "/api/v1/auth/refresh-token";
  const body = {
    refresh_token: getAuth.refresh_token,
  };
  axiosWithAuth
    .post(url, body)
    .then((response) => {
      if (response.data.meta.code === 200) {
        setAuth(response.data.data);
        lastApi.config.headers.Authorization = `Bearer ${response.data.data.access_token}`
        setTimeout(() => {
          axiosWithAuth(lastApi);
        }, 3000);
      }
    })
    .catch(() => {
      localStorage.removeItem('auth')
      window.location = "/lock-screen";
    });
};

export const getMediaToken = async () => {
  try {
    const url = "/api/v1/media/token";
    const response = await axiosWithAuth.get(url);
    if (response?.data?.data) {
      return response.data.data.token;
    }
  } catch (error) {
    toast("error");
    throw error;
  }
};

export const uploadMedia = async (file, token) => {
  try {
    const url = "/api/v1/media/upload";
    const formdata = new FormData();
    formdata.append("file", file)
    formdata.append("token", token)

    const response = await axiosWithAuth.post(url, formdata);

    if (response?.data?.data) {
      return `http://${response.data.data.url}`;
    }
  } catch (error) {
    toast("error");
    throw error;
  }
};

export const uploadMediaWithToken = async (file) => {
  try {
    const token = await getMediaToken();
    const result = await uploadMedia(file, token);
    toast("success", "File berhasil diunggah!");
    return result;
  } catch (error) {
    toast("error", "Gagal mengunggah file media.");
    throw error;
  }
};

export const storeList = async () => {
  const url = "/api/v1/store";
  axiosWithAuth
    .get(url)
    .then((response) => {
      setListStore(response.data.data)
    })
    .catch(() => {
      toast("error", "Gagal update data profil");
    });
};