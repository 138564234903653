<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Merchant</h4>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="page-wrapper-new p-0">
            <div class="content">
              <div class="justify-content-between">
                <div class="page-title">
                  <h4>Create Merchant</h4>
                </div>
              </div>
              <div class="new-employee-field">
                <form @submit.prevent="submitForm">
                  <div class="mb-3">
                    <label class="form-label">Nama Pemilik Usaha</label>
                    <input
                      v-model="owner"
                      type="text"
                      class="form-control"
                      placeholder="Contoh : Ajis Setiawan"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nama Usaha</label>
                    <input
                      v-model="merchant"
                      type="text"
                      class="form-control"
                      placeholder="Contoh : Toko Serbaguna"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Jenis Usaha</label>
                    <vue-select
                      v-model="business"
                      :options="businessType"
                      placeholder="Contoh: Fashion"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Lokasi Usaha</label>
                    <a-select
                      v-model:value="city"
                      mode="single"
                      size="large"
                      :show-search="true"
                      placeholder="Contoh: Jakarta Utara"
                      style="width: 100%"
                      :filter-option="false"
                      :not-found-content="loadingCity ? spinnerContent : cityList === null ? 'Data tidak ditemukan' : undefined"
                      :options="cityList || []"
                      @search="getCity"
                    >
                      <template v-if="loadingCity" #notFoundContent>
                        <a-spin size="small" />
                      </template>
                    </a-select>
                    <small v-if="cityError !== '' && city === ''" class="text-danger">{{
                      cityError
                    }}</small>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Detail Alamat</label>
                    <textarea
                      v-model="address"
                      class="form-control"
                      placeholder="Contoh : jl xxx gang xxx no xxx"
                    />
                  </div>
                  <div class="modal-footer-btn">
                    <button type="submit" class="btn btn-submit w-25">
                      Konfirmasi
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";

export default {
  data() {
    return {
      businessType: [
        "Food & Beverage",
        "Elektronik",
        "Ritel",
        "Fashion",
        "Jasa",
        "Meubel",
      ],
      cityList: [],
      city: "",
      cityError: "",
      loadingCity: false,
      owner: "",
      merchant: "",
      business: "",
      address: "",
      isLoading: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    const merchant = JSON.parse(localStorage.getItem("profile")).merchant;
    if (merchant === null) {
      toast("error", "Please Create Merchant");
      next(false);
    }
    next();
  },
  methods: {
    getCity(search) {
      this.loadingCity = true;
      if (search.length >= 3) {
        this.cityError = "";
        const url = `/api/v1/merchant/city?search=${search}`;
        axiosWithAuth
          .get(url)
          .then((response) => {
            this.loadingCity = false;
            if (response.data.data !== null) {
              this.cityList = response.data.data.map((item) => ({
                value: item.id,
                label: item.name,
              }));
            } else {
              this.cityList = null
            }
          })
          .catch(() => {
            toast("error");
            this.loadingCity = false;
          });
      } else {
        this.cityError = "minimal 3 karakter";
      }
    },
    submitForm() {
      const url = "/api/v1/merchant";
      const body = {
        owner_name: this.owner,
        merchant_name: this.merchant,
        city_id: this.city,
        address: this.address,
        business_type: this.business,
      };
      axiosWithAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            this.$router.push("/dashboard");
          }
        })
        .catch(() => {
          this.isLoading = false;
          toast("error");
        });
    },
  },
};
</script>
